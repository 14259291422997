import React from 'react'
import Layout from '@components/layout'
import { Link } from 'gatsby';
import ServicesTabs from '@components/services-tabs';

export default function services(props) {
  const heroContent = 'Software development is not just about programming; it is about having a vision, aligning great people, and leading them to perform with full commitment and joy. It is about human interaction.'

  return (
    <Layout location={props.location} title='Services' namespace='services' description={heroContent}>
      <div className='hero hero-1'>
        <div className='hero-1__col-1'>
          <div className='title-label-1'>
            <div className='title-label-1__count'>
              02
            </div>
            <div className='title-label-1__seperator'></div>
            <div className='title-label-1__label'>
              Services
            </div>
          </div>
          <h1 className='hero-1__title'>
            Excellence with a personal touch
          </h1>
          <p className='hero-1__content'>
            {heroContent}
          </p>
        </div>
        <div className='hero-1__hero-image hero-1__hero-image--services'>
          <div className='hero-1__image-wrapper'>
            <div className='hero-1__image hero-1__image--services'>
              <img src='/images/laptop1.jpg' alt='' />
            </div>
            <div className='hero-1__bg-pattern hero-1__bg-pattern--services'>
              <img src='/images/hero-services-pattern.png' alt='' />
            </div>
          </div>
        </div>
        <div className='hero-1__col-2'></div>
      </div>
      <section className='solutions-introduction'>
        <div className='content-block-1'>
          <div className='content-block-1__title-label'>
            <h2 className='h2-label'>
              <div className='h2-label__title'>
                Our Team
              </div>
              <div className='h2-label__seperator'></div>
            </h2>
          </div>
          <div className='content-block-1__content'>
            <h3>
              Our solutions are carefully crafted by a team of experts in software development
            </h3>
            <p>
              We are a team of expert web developers who build software using Ruby, Rust, Elixir, and Javascript, with vast experience in Agile methodologies and leadership coaching.
            </p>
            <p>
              For more than 15 years we’ve been developing software for successful startups in Silicon Valley and New York while contributing to many Open Source projects in the Rails, Rust and Ember communities.
            </p>
            <Link className='primary-link primary-link--our-team' to='/about-us'>
              Learn More
            </Link>
          </div>
        </div>
      </section>
      <section className='section--full-width tech-stack'>
        <div className='tech-stack-bg'>
          <div className='tech-stack__wrapper'>
            <div className='tech-stack__col-1'>
              <div className='tech-stack__image'>
                <img src='/images/tech-stack-hero.jpg' alt='expert tech stack' />
              </div>
            </div>
            <div className='tech-stack__col-2'>
              <div className='content-block-2'>
                <div className='content-block-2__title-label'>
                  <h2 className='h2-label'>
                    <div className='h2-label__title'>
                      Tech Stack
                    </div>
                    <div className='h2-label__seperator'></div>
                  </h2>
                </div>
                <div className='content-block-2__content content-block-2__content--alt'>
                  <h3>
                    Expert developers with a passion for clean code
                  </h3>
                  <p>
                    We will expand your team bringing the expertise and leadership you need to further enable your company to grow with sustainable success using our preferred set of languages and tools.
                  </p>
                </div>
              </div>
              <ul className='tech-stack__list'>
                <li className='tech-stack__list-item'>
                  <div className='tech-stack__item-icon'>
                    <div className='icon-dev-icon'></div>
                  </div>
                  <h5 className='tech-stack__item-title'>
                    Full Stack Development
                  </h5>
                  <div className='tech-stack__item-content'>
                    We work in all of the web development stack, backend and frontend, from databases, server-side programming, APIs, and javascript frameworks.
                  </div>
                </li>
                <li className='tech-stack__list-item'>
                  <div className='tech-stack__item-icon'>
                    <div className='icon-rails-icon'></div>
                  </div>
                  <h5 className='tech-stack__item-title'>
                    Ruby on Rails
                  </h5>
                  <div className='tech-stack__item-content'>
                    We love Ruby on Rails for its simplicity, productivity, and how it promotes best practices.
                  </div>
                </li>
                <li className='tech-stack__list-item'>
                  <div className='tech-stack__item-icon'>
                    <div className='icon-elixir-icon'></div>
                  </div>
                  <h5 className='tech-stack__item-title'>
                    Elixir
                  </h5>
                  <div className='tech-stack__item-content'>
                    We are delighted with Elixir and the Phoenix framework, because it allows us to build more robust and scalable solutions while keeping most of the goodness of Ruby on Rails.
                  </div>
                </li>
                <li className='tech-stack__list-item'>
                  <div className='tech-stack__item-icon'>
                    <div className='icon-rust-icon'></div>
                  </div>
                  <h5 className='tech-stack__item-title'>
                    Rust
                  </h5>
                  <div className='tech-stack__item-content'>
                    We found a renewed passion on Rust, given its amazing performance and reliability, while allowing for high development productivity at the same time.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='we-are-passionate'>
        <div className='content-block-2'>
          <div className='content-block-2__title-label'>
            <h2 className='h2-label'>
              <div className='h2-label__title'>
                We're Passionate
              </div>
              <div className='h2-label__seperator'></div>
            </h2>
          </div>
          <div className='content-block-2__content'>
            <h3>
              Leading the way with cutting edge innovation
            </h3>
            <p>
              We love what we do and we are passionate about how we do it. We are constantly exploring and learning new technologies, especially those from communities that have a high standard of professionalism, which are also friendly and inclusive.
            </p>
            <p>
              We devote equal effort to studying how to become better leaders so we can inspire others and bring positive change to organizations.
            </p>
          </div>
        </div>
      </section>

      <ServicesTabs />

      <section className='recent-projects'>
        <div className='masonry__row-1'>
          <div className='masonry__content-block content-block-1'>
            <div className='content-block-1__title-label'>
              <h2 className='h2-label'>
                <div className='h2-label__title'>
                  Recent Projects
                </div>
                <div className='h2-label__seperator'></div>
              </h2>
            </div>
            <div className='content-block-1__content'>
              <h3>
                We love producing results for our clients
              </h3>
              <p>
                For more than 10 years we have worked with successful startups, building relationships that go beyond software to become strong partnerships where we are equally responsible for success.
              </p>
            </div>
          </div>
          <div className='masonry__block'></div>
        </div>
        <div className='masonry__row-2'>
          <div className='masonry__row-2__col-1'>
            <div className='masonry__block masonry__block--2'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/crazyegg-case-card-tall-bg.jpg' alt='' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/web-development1-pattern.png' alt='' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                Crazyegg
              </h5>
              <p className='masonry__copy'>
                WyeWorks helped Crazy Egg with core features for scaling their business.
              </p>
              <Link className='masonry__learn-link' to='/case-studies/crazy-egg'>
                Read Case Study
              </Link>
            </div>
          </div>
          <div className='masonry__row-2__col-2'>
            <div className='masonry__block masonry__block--1'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/wildfire-case-card-tall-bg.jpg' alt='' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/process-consulting1-pattern.png' alt='' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                Wildfire
              </h5>
              <p className='masonry__copy'>
                WyeWorks developed the social media marketing application that allowed companies to monetize their engagements from a host of social platforms.
              </p>
              <Link className='masonry__learn-link' to='/case-studies/wildfire'>
                Read Case Study
              </Link>
            </div>
            <div className='masonry__block masonry__block--3'>
              <div className='masonry__image-container'>
                <div className='masonry__image'>
                  <img src='/images/supremegolf-case-card-tall-bg.jpg' alt='' />
                </div>
                <div className='masonry__pattern'>
                  <img src='/images/innovation1-pattern.png' alt='' />
                </div>
              </div>
              <h5 className='masonry__block-title'>
                Supreme Golf
              </h5>
              <p className='masonry__copy'>
                WyeWorks helped Supreme Golf improve their engineering culture, to enhance quality and value delivery to their customers.
              </p>
              <Link className='masonry__learn-link' to='/case-studies/supreme-golf'>
                Read Case Study
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
