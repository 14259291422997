import React, { Component } from 'react'
import Slider from 'react-slick'
import Tab from '@components/tab'

export default class ServicesTabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      slideIndex: 0,
    }

    this.goToSlide = this.goToSlide.bind(this)
  }

  goToSlide(event, number) {
    event.preventDefault()
    this.slider.slickGoTo(number);
  }

  render() {
    const settings = {
      className: 'tab-content__image-wrapper',
      dots: false,
      fade: true,
      draggable: false,
      arrows: false,
      slidesToShow: 1,
      beforeChange: (current, next) => this.setState({ slideIndex: next })
    }
    return (
      <section className='tab-content services-tabs'>
        <div className='tab-content__container'>
          <Slider
            ref={slider => (this.slider = slider)}
            { ...settings }
          >
            <img className='tab-content__image' src='/images/services-trial-hero.jpg' alt='trial' />
            <img className='tab-content__image' src='/images/services-adapt-hero.jpg' alt='adapt' />
            <img className='tab-content__image' src='/images/services-lead-hero.jpg' alt='lead' />
            <img className='tab-content__image' src='/images/services-transform-hero.jpg' alt='transform' />
          </Slider>
          <ul className='tab-content__list'>
            <Tab
              index={0}
              activeIndex={this.state.slideIndex}
              onClickHandler={this.goToSlide}
            >
              <div className='tab-content__wrapper'>
                <div className='tab-content__count'>
                  01
                </div>
                <div className='tab-content__icon'>
                  <div className='icon-discovery-icon'></div>
                </div>
                <h5 className='tab-content__title'>
                  Trial
                </h5>
              </div>
              <div className='tab-content__wrapper tab-active__wrapper'>
                <div className='tab-content__count tab-active__count'>
                  01
                </div>
                <div className='tab-active__content-wrapper'>
                  <h5 className='tab-active__title'>
                    Trial
                  </h5>
                  <div className='tab-active__content'>
                    Get to know each other at work, experience your process and practices as you get to assess our skill set through real collaboration on real challenges.
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              index={1}
              activeIndex={this.state.slideIndex}
              onClickHandler={this.goToSlide}
            >
              <div className='tab-content__wrapper'>
                <div className='tab-content__count'>
                  02
                </div>
                <div className='tab-content__icon'>
                  <div className='icon-dev-icon'></div>
                </div>
                <h5 className='tab-content__title'>
                  Adapt
                </h5>
              </div>
              <div className='tab-content__wrapper tab-active__wrapper'>
                <div className='tab-content__count tab-active__count'>
                  02
                </div>
                <div className='tab-active__content-wrapper'>
                  <h5 className='tab-active__title'>
                    Adapt
                  </h5>
                  <div className='tab-active__content'>
                    We quickly understand your technology and process as it is and make as much as we can out of it increasing your productivity.
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              index={2}
              activeIndex={this.state.slideIndex}
              onClickHandler={this.goToSlide}
            >
              <div className='tab-content__wrapper'>
                <div className='tab-content__count'>
                  03
                </div>
                <div className='tab-content__icon'>
                  <div className='icon-testing-icon'></div>
                </div>
                <h5 className='tab-content__title'>
                  Lead
                </h5>
              </div>
              <div className='tab-content__wrapper tab-active__wrapper'>
                <div className='tab-content__count tab-active__count'>
                  03
                </div>
                <div className='tab-active__content-wrapper'>
                  <h5 className='tab-active__title'>
                    Lead
                  </h5>
                  <div className='tab-active__content'>
                    We propose changes to both technology and process as we see the potential to bring more value to your business.
                  </div>
                </div>
              </div>
            </Tab>
            <Tab
              index={3}
              activeIndex={this.state.slideIndex}
              onClickHandler={this.goToSlide}
            >
              <div className='tab-content__wrapper'>
                <div className='tab-content__count'>
                  04
                </div>
                <div className='tab-content__icon'>
                  <div className='icon-graph-icon'></div>
                </div>
                <h5 className='tab-content__title'>
                  Transform
                </h5>
              </div>
              <div className='tab-content__wrapper tab-active__wrapper'>
                <div className='tab-content__count tab-active__count'>
                  04
                </div>
                <div className='tab-active__content-wrapper'>
                  <h5 className='tab-active__title'>
                    Transform
                  </h5>
                  <div className='tab-active__content'>
                    We work with the leaders of your company to help provide better direction and vision for both your team and us so that everybody performs at a higher level.
                  </div>
                </div>
              </div>
            </Tab>
          </ul>
        </div>
      </section>
    )
  }
}
